import * as React from "react"
import { Link } from "gatsby"

const divStyle = {
  height: '100vh'
}
const posiCC = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateY(-50%) translateX(-50%)',
  margin: 'auto',
}
const linkStyle = {
  deisplay: 'inline-block',
  margin: '50px',
}


const IndexPage = () => {

  return (
    <div className="pageIndex">
      {/* CONTENTS */}

      <div style={divStyle}>
        <div style={posiCC}>

        <Link
          to="/three/"
          style={linkStyle}
        >
          パノラマ
        </Link>
        <Link
          to="/room/"
          style={linkStyle}
        >
          ルーム
        </Link>
        </div>
      </div>



      {/* CONTENTS */}
    </div>
  )
}

export default IndexPage
